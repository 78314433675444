import React from 'react'

import Routes from './routes'

import './App.css'

const App: React.FC = () => <Routes/>

export default App

